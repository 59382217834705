/* Reset */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/* Basic styling */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}

/*  Set `margin-bottom` to maintain vertical rhythm */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * 0.75;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $heavy-font-weight;
}

a {
  color: $brand-color;

  &:visited {
    color: $brand-color-dark;
  }

  &:hover {
    color: $compliment-color;
  }
}

/* Header links (no underline) */
a.link-header {
  text-decoration: none;

  &:hover {
    color: $compliment-color;
    text-decoration: underline;
  }
}

/* Tags (inverted links) */
a.tag {
 color: $subtle-color;
 font-weight: bold;
 text-decoration: none;
 vertical-align: middle;

 &:visited {
   color: $subtle-color-dark;
 }

 &:hover {
   background: $compliment-color;
   color: $subtle-color-dark;
   text-decoration: none;
 }
}

.tag {
  background: $brand-color-dark;
  border-radius: 0.25em;
  color: $subtle-color-dark;
  padding: 0.1em 0.5em;
}

blockquote {
  color: $accent-color;
  border-left: 4px solid $accent-color-light;
  padding-left: math.div($spacing-unit, 2);
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

pre,
code {
  font-size: 14px;
  border: 1px solid #9C3B6B;
  border-radius: 3px;
  background-color: #3C1E31;
  color: #FFCDE5;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    position: relative;
    @extend %clearfix;

    @include media-query($laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: math.div($spacing-unit, 2);
        padding-left: math.div($spacing-unit, 2);
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        height: 16px;
        vertical-align: middle;
        width: 16px;

        path {
            fill: $accent-color;
        }
    }
}

span + .icon {
  margin-left: 5px;
}


.subtle {
  color: $accent-color-light;
}
