


/**
 * Site footer
 */
.site-footer {
    border-top: 5px solid $subtle-color-dark;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: math.div($spacing-unit, 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    // color: $accent-color;
    margin-left: -1 * math.div($spacing-unit, 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: math.div($spacing-unit, 2);
    padding-left: math.div($spacing-unit, 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($phone) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }

    .post-date + .post-categories {
      margin-left: 5px;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $accent-color-light;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 1.4em;

        @include media-query($laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 1.2em;

        @include media-query($laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 1em;

        @include media-query($laptop) {
            font-size: 18px;
        }
    }
}

strong {
  font-weight: 600;
}