.thing-gallery {
  .thing {
    display: inline-block;
    border: 3px solid $subtle-color-dark;
    width: 25vw;
    &:hover {
      border-color: $compliment-color;
    }
  }
  img, video {
    max-width: 25vw;
  }
}

// figure {
//   max-height: 50vh;
// }

figure {
  figcaption {
    color: $subtle-color;
    font-size: 1em;
  }
  img, video {
    max-height: 100%;
  }
  &.mini {
    img, video {
      max-height: 50%;
    }
  }
}

.figure-set {
  background: $subtle-color-dark;
  border: 2px solid $accent-color-light;
  border-radius: 6px;
  display: table;
  margin: 1em auto;
  padding: 0.25em;
  width: 100%;
  .figure-set-inner {
    display: table-row;
  }
  figure {
    display: table-cell;
    padding: 0.25em;
    vertical-align: middle;
  }
}

@include media-query($phone) {
  .thing-gallery {
    .thing {
      width: 100%;
    }
    img, video {
      max-height: auto;
      max-width: auto;
    }
  }
  figure {
    width: 100%;
  }
}