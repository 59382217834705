/* body {
  font-family: 'Comic Neue', sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5;
  font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  -webkit-font-feature-settings: "kern";
} */

@font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Regular.eot');
  src: url($base-font-file-name+'-Regular.woff2') format('woff2'),
       url($base-font-file-name+'-Regular.woff') format('woff'),
       url($base-font-file-name+'-Regular.ttf')  format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Bold.eot');
  src: url($base-font-file-name+'-Bold.woff2') format('woff2'),
       url($base-font-file-name+'-Bold.woff') format('woff'),
       url($base-font-file-name+'-Bold.ttf')  format('truetype');
  font-weight: 700;
}

/* Unused */
/*

 	font-weight: 100; Comic Neue Light
 	font-weight: 300; Comic Neue Regular
 	font-weight: 700; Comic Neue Bold
	font-style: oblique; for the oblique equivalents.

  Change 'Comic Neue' to 'Comic Neue Angular' for the Angular variants
  For example Comic Neue Angular Bold Oblique would be:

 	body {
		font-family: 'Comic Neue Angular', sans-serif;
		font-style: oblique;
		font-weight: 700;
    font-size: 1em;
    line-height: 1.5;
 	}
	or in shorthand: body { font: oblique 700 1em/1.5 'Comic Neue Angular', sans-serif; }

*/

/*
@font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Light.eot');
  src: url($base-font-file-name+'-Light.eot?#iefix') format('embedded-opentype'),
       url($base-font-file-name+'-Light.woff2') format('woff2'),
       url($base-font-file-name+'-Light.woff') format('woff'),
       url($base-font-file-name+'-Light.ttf')  format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Light-Oblique.eot');
  src: url($base-font-file-name+'-Light-Oblique.eot?#iefix') format('embedded-opentype'),
       url($base-font-file-name+'-Light-Oblique.woff2') format('woff2'),
       url($base-font-file-name+'-Light-Oblique.woff') format('woff'),
       url($base-font-file-name+'-Light-Oblique.ttf')  format('truetype');
  font-weight: 300;
  font-style: oblique;
} */

/* @font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Regular-Oblique.eot');
  src: url($base-font-file-name+'-Regular-Oblique.woff2') format('woff2'),
       url($base-font-file-name+'-Regular-Oblique.woff') format('woff'),
       url($base-font-file-name+'-Regular-Oblique.ttf')  format('truetype');
  font-weight: 400;
  font-style: oblique;
} */

/* @font-face {
  font-family: 'Comic Neue';
  src: url($base-font-file-name+'-Bold-Oblique.eot');
  src: url($base-font-file-name+'-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
       url($base-font-file-name+'-Bold-Oblique.woff2') format('woff2'),
       url($base-font-file-name+'-Bold-Oblique.woff') format('woff'),
       url($base-font-file-name+'-Bold-Oblique.ttf')  format('truetype');
  font-weight: 700;
  font-style: oblique;
} */

