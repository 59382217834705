@use "sass:math";
@charset "utf-8";


$background-color: #2D2D2D;
$brand-color: hsla(331,90%,60%,1);
$brand-color-dark: darken($brand-color, 20%);
$compliment-color: #96f53d;

$accent-color: $brand-color;
$accent-color-light: lighten($accent-color, 20%);
$accent-color-dark: darken($accent-color, 20%);

$subtle-color: hsla(0, 0%, 70%, 1);
$subtle-color-dark: #252525;

$content-width: 840px;

$phone: 600px;
$laptop: 840px;

$base-font-family: "Comic Neue", sans-serif;
$base-font-file-name: '/fonts/ComicNeue';
$base-font-size: 1.5em;
$base-font-weight: 400;
$heavy-font-weight: 500;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;
$nav-height: 40px;

$spacing-unit: 30px;

$text-color: #ededed;

// Use media queries like this:
// @include media-query($phone) {
//     .wrapper {
//         padding-right: math.div($spacing-unit, 2);
//         padding-left: math.div($spacing-unit, 2);
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@import
  "font",
  "base",
  "header",
  "layout",
  "shared",
  "index"
;
