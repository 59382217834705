/**
 * Site header
 */

.site-header a {
  text-decoration: none;

  &:hover {
    // color: $compliment-color;
    text-decoration: underline;
  }
}

.link-category {
  // left: -50vw;
  font-size: 0.8em;
  margin-right: 20px;
  // position: absolute;
  right: -webkit-calc(#{$content-width} - #{$spacing-unit});
  right:         calc(#{$content-width} - #{$spacing-unit});
  text-align: right;
  // a, .link-category-spacer {
  //   color: $accent-color-dark;
  //   line-height: $nav-line-height;
  // }
  // .link-category-spacer {
  //   font-weight: 600;
  // }
}

.site-nav {
  // height: $nav-height;
  text-align: center;
}

.site-title {
  // float: right;
  // font-weight: bold;
  // line-height: $nav-line-height;

  &,
  &:visited {
      color: $accent-color-dark;
  }
}

.page-link {
  // color: $accent-color-light;
  line-height: $nav-height;

  // Gaps between nav items, but not on the last one
  &:not(:last-child) {
      margin-right: 20px;
  }
}

.link-current-page {
  // font-weight: bold;
  // font-size: 1.1em;
  // line-height: $nav-line-height;
  display: inline-block;
}

.current-page {
  border-bottom: 3px solid #fff;
  border-top: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    $subtle-color-dark 0%,
    $accent-color-light,
    $subtle-color-dark 100%
  );
  box-shadow: 0 0 3px $subtle-color-dark;
  font-size: 1.5em;
  // height: 70px;

  // Positioning context for the mobile navigation icon
  // position: relative;

  // float: right;
  text-align: center;
  width: 100%;

  @include media-query($phone) {
      top: 9px;
      right: math.div($spacing-unit, 2);
      background-color: $background-color;
      // border: 1px solid $accent-color-light;
      border-radius: 5px;

      .trigger {
          clear: both;
          display: none;
      }

      &:hover .trigger {
          display: block;
          padding-bottom: 5px;
      }

      .page-link {
          display: block;
          text-transform: capitalize;
          padding: 5px 10px;

          &:not(:last-child) {
              margin-right: 0;
          }
          margin-left: 20px;
      }
  }
}